/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react';
import {
    DynamicDataSheetGrid,
    keyColumn,
    Column,
    createTextColumn,
    CellProps,
} from 'react-datasheet-grid';
import {useMutation} from '@apollo/client';
import {AddMutationsDocument} from '../../../graphql-types';
import CustomCellComponent from './customCellComponent';
import {
    parseErrors,
    updateDataWithErrors,
    transformToBackendFormat,
    countErrors,
} from '../../../helpers/bulkImport';
import type {DataItem, RowError, SuccessResponse} from '../../../interfaces/bulkImport';
import {BACKEND_ERRORS, COLUMN_CONFIG, DEFAULT_ROW} from '../../../configs/pages/videos/bulkImport';
import type {BulkImportAction} from '../../../helpers/reducers/bulkImportReducer';

interface BulkImportDatasheetProps {
    data: DataItem[],
    dispatch: React.Dispatch<BulkImportAction>,
    onErrors: (count: number) => void,
}

interface DatasheetRefMethods {
    triggerMutation: () => void,
}

const BulkImportDatasheet = forwardRef(({
    data,
    dispatch,
    onErrors,
}: BulkImportDatasheetProps, ref: React.Ref<DatasheetRefMethods>) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const columns: Column<DataItem>[] = useMemo(() => {
        const createCustomColumn = <K extends keyof DataItem>(key: K, title: string, minWidth: number): Partial<Column<DataItem>> => ({
            ...keyColumn(key, createTextColumn()),
            title,
            minWidth,
            component: (props: CellProps<DataItem, any>) => <CustomCellComponent {...props} dispatch={dispatch} />,
            copyValue: ({rowData}: {rowData: DataItem}) => rowData[key].value,
            pasteValue: ({rowData, value}: {rowData: DataItem; value: string}) => ({
                ...rowData,
                [key]: {value},
            }),
            deleteValue: ({rowData}: {rowData: DataItem}) => ({
                ...rowData,
                [key]: {value: ''},
            }),
        });

        return COLUMN_CONFIG.map(({key, title, minWidth}) => createCustomColumn(key, title, minWidth));
    }, []);

    const [addMutations, {loading}] = useMutation(AddMutationsDocument, {
        onCompleted: completeData => {
            dispatch({type: 'SET_ITEMS_TO_CREATE', payload: completeData.addMutations as SuccessResponse});
            onErrors(0);
            dispatch({type: 'SET_ERRORS_CHECKED', payload: true});
        },
        onError: errorData => {
            if (errorData.message !== BACKEND_ERRORS.badInput) {
                dispatch({type: 'SET_ERROR_MESSAGE', payload: errorData.message});

                return;
            }

            const [errorObject] = errorData.graphQLErrors;

            const parsedErrors = parseErrors(errorObject?.extensions?.errors as RowError[]);

            const newData = updateDataWithErrors(data, parsedErrors);
            const errorCount = countErrors(data);

            dispatch({type: 'SET_DATA', payload: newData});
            onErrors(errorCount);
            dispatch({type: 'SET_ERRORS_CHECKED', payload: false});
        },
    });

    const dispatchMutation = () => {
        const dataToSend = data.map(transformToBackendFormat);
        const dataToSendWithoutEmptyRows = dataToSend.filter(row => (!Object.values(row).every(cell => [
            '',
            undefined,
            null,
        ].includes(cell))));

        addMutations({
            variables: {
                inputs: dataToSendWithoutEmptyRows,
                validateOnly: true,
            },
        });
    };

    useImperativeHandle(ref, () => ({
        triggerMutation: dispatchMutation,
    }), [data]);

    const handlePaste = useCallback((event: React.ClipboardEvent) => {
        if (isFocused) {
            return;
        }

        const pasteData = event.clipboardData.getData('text/plain');
        const rows = pasteData.split('\n').map(row => row.split('\t'));

        // have to remove last item in row because it's always empty and table will have one extra unnecessery row
        if (rows.length && rows[rows.length - 1].every(cell => cell.trim() === '')) {
            rows.pop();
        }

        const keys = Object.keys(DEFAULT_ROW()) as (keyof DataItem)[];

        const newData: DataItem[] = rows.map(row => keys.reduce((acc, key, index) => {
            // eslint-disable-next-line no-param-reassign
            acc[key] = {value: row[index] || ''};

            return acc;
        }, {} as DataItem));

        dispatch({type: 'SET_DATA', payload: newData});
    }, [isFocused]);

    useEffect(() => {
        document.addEventListener('paste', handlePaste as any);

        return () => {
            document.removeEventListener('paste', handlePaste as any);
        };
    }, [handlePaste]);

    useEffect(() => {
        dispatch({type: 'SET_IS_LOADING', payload: loading});
    }, [loading]);

    return (
        <DynamicDataSheetGrid
            value={data}
            onChange={tableData => {dispatch({type: 'SET_DATA', payload: tableData});}}
            columns={columns}
            onActiveCellChange={e => {
                if (e.cell === null) {
                    setIsFocused(false);

                    return;
                }

                setIsFocused(true);
            }}
            createRow={DEFAULT_ROW}
        />
    );
});

export default BulkImportDatasheet;
